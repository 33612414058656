import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { UserService, UserModel, ImagesService, DashboardService, FeaturesModel, AuthenticationService } from 'src/app/shared';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public currentUser: UserModel = new UserModel();
  public images;
  public featuresList: FeaturesModel[] = [];
  public hovered: boolean = false;
  public internalUser;
  public externalUser;
  public hasPermission: boolean = false;
  public expanded: boolean = true;
  public hasInteracted: boolean = false;
  public version: string = environment?.version;
  private a1Login: string = environment?.a1Login;

  constructor(
    private userService: UserService,
    private imageService: ImagesService,
    private router: Router,
    private features: DashboardService,
    public translate: TranslateService,
    private authService: AuthenticationService,
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.images = this.imageService.imgSrc;
    this.getCurrentUser();
    this.internalUser = this.authService.getToken();
  }

  public getCurrentUser(): void {
    this.currentUser = this.userService.loggedUser;
    this.hasPermission = this.userService.isAllowedToAccess(this.currentUser);
    this.externalUser = this.authService.headers;
    this.getFeatures();
  }
  public getFeatures(): void {
    this.features.getLatestFeatures().subscribe(features => {
      this.featuresList = features;
    });
  }

  onAddNew(event: Event, path: string) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([path]);
  }

  public logout(): void {
    this.internalUser ?
      this.authService.logout() :
      window.location.href = this.a1Login
  }

  public handleSidebar(): void {
    this.expanded = !this.expanded
    if (!this.hasInteracted) {
      this.hasInteracted = true;
    }
  }
}
