export * from "./avatar-initials.pipe";
export * from "./full-name.pipe";
export * from "./dateFormat.pipe";
export * from "./sortBy.pipe";
export * from "./eol-correct-date.pipe";
export * from "./truncate.pipe";
export * from "./device-activity.pipe";
export * from './robot-action.pipe';
export * from './is-new-device.pipe'
export * from './file-names.pipe'
