<div class="sidebar" [ngClass]="{'closed': !expanded, 'open': expanded && hasInteracted}">
  <a routerLink="/" class="sidebar-brand-container"[ngClass]="{'my-4': externalUser}">
    <div class="sidebar-brand">
    </div>
  </a>
  <div tabindex="0" class="expand-button" [ngClass]="{'closed': !expanded, 'open': expanded && hasInteracted}" (click)="handleSidebar()">
    <span class="material-icons">
      first_page
    </span>
  </div>
  <!-- <div class="sidebar-brand_2">
    <a routerLink="/"></a>
  </div> -->
  <!-- Navigation -->
  <div class="sidebar-nav" *ngFor="let image of images">
      <div class="sidebar-group-one">
        <div class="nav-group">
          <ul>
            <li class="nav-item">
              <a [routerLink]="[ '/dashboard' ]" routerLinkActive="active" #rla="routerLinkActive">
                <span class="material-icons sidebar-icons">
                  dashboard
                </span>
                <span class="nav-label">{{'SIDEBAR.Dashboard' | translate }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="nav-group">
          <ul>
            <li class="nav-item">
              <a [routerLink]="[ '/branch/list' ]" routerLinkActive="active" #rlaBranch="routerLinkActive">
                <span class="material-icons-outlined sidebar-icons">
                  business
                </span>
                <span class="nav-label">{{'SIDEBAR.Branches' | translate }}</span>
                <span class="badge badge-pill " role="button" tabindex="0"
                  (click)="onAddNew($event, '/branch/form/new')"
                  *ngIf="!currentUser?.read_only && internalUser">
                  <span class="material-icons">
                    add
                  </span>
                </span>
              </a>
            </li>
            <li class="nav-item"
              *ngIf="hasPermission">
              <a [routerLink]="[ '/users/list' ]" routerLinkActive="active" #rlaUsers="routerLinkActive">
                <span class="material-icons sidebar-icons">
                  manage_accounts
                </span>
                <span class="nav-label">{{'SIDEBAR.Managers' | translate }}</span>
                <span class="badge badge-pill " role="button" tabindex="0" (click)="onAddNew($event, '/users/form/new')"
                  *ngIf="!currentUser?.read_only">
                  <span class="material-icons">
                    add
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="nav-group">
          <ul>
            <li class="nav-item">
              <a [routerLink]="[ '/employees/list' ]" routerLinkActive="active" #rlaEmployee="routerLinkActive"
                type="button" class="">
                <span class="material-icons sidebar-icons">
                  badge
                </span>
                <span class="nav-label">{{'SIDEBAR.Employees' | translate }}</span>
                <span class="badge badge-pill " role="button" tabindex="0"
                  (click)="onAddNew($event, '/employees/form/new')" *ngIf="!currentUser?.read_only">
                  <span class="material-icons">
                    add
                  </span>
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[ '/devices/list' ]" routerLinkActive="active" #rlaDevice="routerLinkActive"
                type="button" class="">
                <span class="material-icons sidebar-icons">
                  smartphone
                </span>
                <span class="nav-label">{{'SIDEBAR.Devices' | translate }}</span>
                <span class="badge badge-pill  " role="button" tabindex="0" (click)="onAddNew($event, '/devices/form')"
                  *ngIf="!currentUser?.read_only && internalUser">
                  <span class="material-icons">
                    add
                  </span>
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[ '/break-and-fix' ]" routerLinkActive="active" #rlaBreakAndFix="routerLinkActive"
                type="button" class="">
                <span class="material-icons sidebar-icons">
                  construction
                </span>
                <span class="nav-label">{{'SIDEBAR.Break & Fix' | translate }}</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="!externalUser">
              <a [routerLink]="[ '/orders' ]" routerLinkActive="active" #rlaOrders="routerLinkActive" type="button"
                class="beta-container">
                <span class="material-icons sidebar-icons material-icons-outlined">
                  shopping_cart
                </span>
                <span class="nav-label">{{'SIDEBAR.Orders' | translate }}</span>
                <span class="beta">BETA</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="!externalUser">
              <a [routerLink]="[ '/archived-devices' ]" routerLinkActive="active" #rlaArchived="routerLinkActive"
                type="button">
                <span class="material-icons sidebar-icons material-icons-outlined">
                  inventory_2
                </span>
                <span class="nav-label">{{'SIDEBAR.Archive' | translate }}</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="!externalUser">
              <a [routerLink]="[ '/robot' ]" routerLinkActive="active" #rlaRobot="routerLinkActive" type="button"
                class="">
                <span class="material-icons sidebar-icons">
                  <img
                    src="/assets/icons/robot-{{rlaRobot.isActive ? 'white' : 'blue'}}-icon.{{rlaRobot.isActive ? 'png' : 'svg'}}"
                    class="d-flex" alt="">
                </span>
                <span class="nav-label">{{'SIDEBAR.Robot' | translate }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar-group-two">
        <div class="nav-group  nav-bottom" *ngIf="externalUser || !currentUser?.user_roles?.includes('manager')">
          <ul class="sidebar-bottom__links">
            <li class="nav-item" *ngIf="!currentUser?.read_only">
              <a [routerLink]="[ '/EOL' ]" routerLinkActive="active" #rlaEol="routerLinkActive">
                <span class="material-icons sidebar-icons">
                  settings
                </span>
                <span class="nav-label">{{'SIDEBAR.EOL Settings' | translate }}</span>
              </a>
            </li>
            <li class="nav-item sign-out">
              <a routerLinkActive="active" #rlaSignOut="routerLinkActive" (click)="logout()">
                <span class="material-icons sidebar-icons">
                  logout
                </span>
                <span class="nav-label">{{(internalUser ? 'SIDEBAR.Sign out' : 'SIDEBAR.Go back') | translate}}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="version">
          v{{ version }}
        </div>
      </div>
  </div>
  <!-- Spacer -->
  <!-- <div class="sidebar-spacer"></div> -->
  <!-- Util -->
  <!-- <div class="sidebar-footer">
    </div> -->
</div>
