<div>
  <div [ngClass]="{'backdrop': showInfo}" (click)="showInfo = false"></div>
  <div class="panel-wrap" [ngClass]="{'panel-show': showInfo}">
    <div class="panel">
      <div class="nav">
        <h1 class="mb-0"> {{'DEVICEINFO.Edit device info' | translate}}</h1> <span (click)="showInfo=false"><img
            src="../../../../assets/icons/x-button.png"></span>
      </div>
      <div class="divider"></div>
      <br />
      <h2>{{'DEVICEINFO.Device specifications' | translate}}</h2>
      <form [formGroup]="deviceForm" (ngSubmit)="confirm()">
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'DEVICEFORM.Brand' | translate}}</mat-label>
                  <mat-select formControlName="brand_id" (selectionChange)="getDeviceModels($event.value)">
                    <mat-option *ngFor="let brand of brands"
                      [value]="brand?.brand_id">{{brand?.brand?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'DEVICEFORM.Model' | translate}}</mat-label>
                  <mat-select formControlName="device_model_id"
                    (selectionChange)="getDeviceVariants(deviceForm.get('brand_id').value, deviceForm.get('device_model_id').value)">
                    <mat-option *ngFor="let model of deviceModels" [value]="model?.id">{{model?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'DEVICEFORM.Variant-field' | translate}}</mat-label>
              <mat-select formControlName="device_variant_id">
                <mat-option *ngFor="let variant of deviceVariants" [value]="variant?.id">{{variant?.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="device_value full-width">
              <mat-label>{{'DEVICEFORM.Reference' | translate}}</mat-label>
              <input type="text" formControlName="reference_number" matInput placeholder="Reference number">
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'DEVICEFORM.Serial' | translate}}</mat-label>
              <input type="text" formControlName="serial_number" matInput />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'DEVICEFORM.IMEI' | translate}}</mat-label>
              <input type="text" formControlName="imei" matInput />
            </mat-form-field>
          </div>
        </div>

        <h2 class="sup-text">{{'DEVICEINFO.Order info' | translate}}</h2>
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'DEVICEFORM.Device supplier' | translate}}</mat-label>
                  <mat-select formControlName="device_supplier_id">
                    <mat-option [value]='null'>N/A</mat-option>
                    <mat-option *ngFor="let supplier of deviceSuppliers"
                      [value]="supplier.id">{{supplier?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-6">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'DEVICEINFO.Order number' | translate}}</mat-label>
                  <input type="text" matInput formControlName="order_number">
                </mat-form-field>
              </div>
            
              <div class="col-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label class="device-branch__form-field">
                    {{'DEVICEINFO.Order type' | translate}}
                  </mat-label>
                  <mat-select formControlName="order_type">
                    <mat-option value="Buy">{{'DEVICELIST.Buy' | translate}}</mat-option>
                    <mat-option value="Rent">{{'DEVICELIST.Rent' | translate}}</mat-option>
                    <mat-option value="Leasing">{{'DEVICELIST.Leasing' | translate}}</mat-option>
                    <mat-option value="A1 BHWR">A1 BHWR</mat-option>
                    <mat-option value="A1 DaaS">A1 DaaS</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="device.device_type_id !== 5 && device.device_type_id !== 3">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <h3>{{'DEVICEFORM.SIM Card' | translate }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'DEVICEFORM.Mobile provider' | translate }}</mat-label>
                  <mat-select formControlName="mobile_provider_id">
                    <mat-option [value]='null'>N/A</mat-option>
                    <mat-option *ngFor="let mobileProvider of mobileProviders" [value]="mobileProvider.id">
                      {{ mobileProvider.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'DEVICEFORM.SIM' | translate}}</mat-label>
                  <input matInput formControlName="sim">
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'DEVICEFORM.Phone number' | translate}}</mat-label>
                  <input matInput formControlName="phone_number">
                  <mat-error>
                    {{'DEVICEFORM.Phone number is not' | translate }} <strong> {{'DEVICEFORM.valid' | translate }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
        <h2 class="sup-text">{{'DEVICEINFO.Insurance info' | translate}}</h2>
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'DEVICEINFO.Insurance supplier' | translate}}</mat-label>
                  <mat-select formControlName="insurance_id">
                    <mat-option [value]='null'>N/A</mat-option>
                    <mat-option *ngFor="let insurance of deviceInsurance"
                      [value]="insurance.id">{{insurance?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <h2 class="sup-text">{{'DEVICEINFO.Ticket information' | translate}}</h2>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Ticket ID</mat-label>
              <input type="text" matInput formControlName="ticket_id">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <h3>{{'DEVICEFORM.Additional info' | translate }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'DEVICEFORM.Additional Note' | translate}}</mat-label>
              <textarea matInput rows="3" formControlName="additional_note"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="example-button-row">
          <button mat-button (click)="showInfo=false" class="btn btn-cancel mr-1">{{'DEVICEFORM.Cancel' | translate |
            titlecase}}</button>
          <button mat-button color="#FFFFFF" [disabled]="!submitable" class="btn btn-primary"
            type="submit">{{'DEVICEFORM.Save changes' | translate | titlecase}}</button>
        </div>

      </form>
    </div>
  </div>
</div>