    <div class="icon-device-phone " [ngClass]="{'selected': selected}" >
        <!-- phone -->
        <svg *ngIf="deviceTypeId === 1" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
            <path d="M16 11C16 9.89543 16.8954 9 18 9H30C31.1046 9 32 9.89543 32 11V37C32 38.1046 31.1046 39 30 39H18C16.8954 39 16 38.1046 16 37V11Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30 9C31.1046 9 32 9.89543 32 11V37C32 38.1046 31.1046 39 30 39H18C16.8954 39 16 38.1046 16 37V11C16 9.89543 16.8954 9 18 9H30ZM28.9542 10.3H30C30.3866 10.3 30.7 10.6134 30.7 11V37C30.7 37.3866 30.3866 37.7 30 37.7H18C17.6134 37.7 17.3 37.3866 17.3 37V11C17.3 10.6134 17.6134 10.3 18 10.3H19.0458C19.1732 10.7057 19.5522 11 20 11H28C28.4478 11 28.8268 10.7057 28.9542 10.3Z" fill="#86909e"/>
        </svg>
        <!-- tablet -->
        <svg *ngIf="deviceTypeId === 2" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
            <path d="M13 11C13 9.89543 13.8954 9 15 9H33C34.1046 9 35 9.89543 35 11V37C35 38.1046 34.1046 39 33 39H15C13.8954 39 13 38.1046 13 37V11Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33 10.3H15C14.6134 10.3 14.3 10.6134 14.3 11V37C14.3 37.3866 14.6134 37.7 15 37.7H33C33.3866 37.7 33.7 37.3866 33.7 37V11C33.7 10.6134 33.3866 10.3 33 10.3ZM15 9C13.8954 9 13 9.89543 13 11V37C13 38.1046 13.8954 39 15 39H33C34.1046 39 35 38.1046 35 37V11C35 9.89543 34.1046 9 33 9H15Z" fill="#86909e"/>
        </svg>
        <!-- notebook -->
        <svg *ngIf="deviceTypeId === 3" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
            <path d="M9.2666 15.5C9.2666 14.6716 9.93817 14 10.7666 14H37.2333C38.0617 14 38.7333 14.6716 38.7333 15.5V32.5C38.7333 33.3284 38.0617 34 37.2333 34H10.7666C9.93817 34 9.2666 33.3284 9.2666 32.5V15.5Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.26672 15.5C9.26672 14.6716 9.9383 14 10.7667 14H37.2334C38.0618 14 38.7334 14.6716 38.7334 15.5V32.5C38.7334 32.6753 38.7033 32.8436 38.648 33H41C41 33.5523 40.5523 34 40 34H8C7.44772 34 7 33.5523 7 33H9.35208C9.2968 32.8436 9.26672 32.6753 9.26672 32.5V15.5ZM10.7667 15.3H37.2334C37.3438 15.3 37.4334 15.3895 37.4334 15.5V32.5C37.4334 32.6105 37.3438 32.7 37.2334 32.7H10.7667C10.6563 32.7 10.5667 32.6105 10.5667 32.5V15.5C10.5667 15.3895 10.6563 15.3 10.7667 15.3Z" fill="#86909e"/>
        </svg>
        <!-- other (gear) -->
        <svg class="other-icon" *ngIf="deviceTypeId === 4" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
            <path d="M9.2666 15.5004C9.2666 14.6719 9.93817 14.0004 10.7666 14.0004H37.2333C38.0617 14.0004 38.7333 14.6719 38.7333 15.5004V32.5004C38.7333 33.3288 38.0617 34.0004 37.2333 34.0004H10.7666C9.93817 34.0004 9.2666 33.3288 9.2666 32.5004V15.5004Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.26703 15.5002C9.26703 14.6718 9.9386 14.0002 10.767 14.0002H37.2337C38.0621 14.0002 38.7337 14.6718 38.7337 15.5002V32.5002C38.7337 32.6756 38.7036 32.8439 38.6483 33.0002H41.0003C41.0003 33.5525 40.5526 34.0002 40.0003 34.0002H8.00031C7.44802 34.0002 7.00031 33.5525 7.00031 33.0002H9.35238C9.29711 32.8439 9.26703 32.6756 9.26703 32.5002V15.5002ZM10.767 15.3002H37.2337C37.3442 15.3002 37.4337 15.3898 37.4337 15.5002V32.5002C37.4337 32.6107 37.3442 32.7002 37.2337 32.7002H10.767C10.6566 32.7002 10.567 32.6107 10.567 32.5002V15.5002C10.567 15.3898 10.6566 15.3002 10.767 15.3002Z" fill="#86909e"/>
            <path d="M29.6 14.2934C29.6 13.4686 30.2268 12.8 31 12.8H39.4C40.1732 12.8 40.8 13.4686 40.8 14.2934V33.7067C40.8 34.5315 40.1732 35.2 39.4 35.2H31C30.2268 35.2 29.6 34.5315 29.6 33.7067V14.2934Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M40 12C40.8836 12 41.6 12.7163 41.6 13.6V34.4C41.6 35.2837 40.8836 36 40 36H30.4C29.5163 36 28.8 35.2837 28.8 34.4V13.6C28.8 12.7163 29.5163 12 30.4 12H40ZM39.1634 13.04H40C40.3093 13.04 40.56 13.2907 40.56 13.6V34.4C40.56 34.7093 40.3093 34.96 40 34.96H30.4C30.0907 34.96 29.84 34.7093 29.84 34.4V13.6C29.84 13.2907 30.0907 13.04 30.4 13.04H31.2366C31.3386 13.3646 31.6418 13.6 32 13.6H38.4C38.7582 13.6 39.0614 13.3646 39.1634 13.04Z" fill="#86909e"/>
            <path d="M35.2 25.6455C35.2 24.8171 35.8716 24.1455 36.7 24.1455H41.0846C41.913 24.1455 42.5846 24.8171 42.5846 25.6455V31.5545C42.5846 32.3829 41.913 33.0545 41.0846 33.0545H36.7C35.8716 33.0545 35.2 32.3829 35.2 31.5545V25.6455Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2 25.6454C35.2 25.005 35.6013 24.4584 36.1661 24.2432C36.7334 23.3037 36.8339 22.6866 36.8411 21.6H40.9436C40.9509 22.6867 41.0513 23.3037 41.6187 24.2433C42.1834 24.4586 42.5846 25.0051 42.5846 25.6454V31.5544C42.5846 32.1948 42.1833 32.7415 41.6184 32.9566C41.0511 33.8961 40.9507 34.5132 40.9435 35.5998L36.8409 35.5998C36.8336 34.5131 36.7332 33.8961 36.1658 32.9565C35.6012 32.7413 35.2 32.1947 35.2 31.5544V25.6454ZM41.3387 32.418L36.4458 32.418C36.0726 32.3084 35.8 31.9632 35.8 31.5544V25.6454C35.8 25.2366 36.0726 24.8914 36.446 24.7818H41.3386C41.712 24.8914 41.9846 25.2366 41.9846 25.6454V31.5544C41.9846 31.9632 41.712 32.3084 41.3387 32.418Z" fill="#86909e"/>
            <path d="M42.8923 26.6908C42.7223 26.6908 42.5846 26.8286 42.5846 26.9985V28.2922C42.5846 28.4622 42.7223 28.5999 42.8923 28.5999C43.0622 28.5999 43.2 28.4622 43.2 28.2922V26.9985C43.2 26.8286 43.0622 26.6908 42.8923 26.6908Z" fill="#040E6C"/>
        </svg>
        <!-- desktop -->
        <svg *ngIf="deviceTypeId === 5" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
            <path d="M8 13.5C8 12.6716 8.67157 12 9.5 12H38.5C39.3284 12 40 12.6716 40 13.5V31.0714C40 31.8999 39.3284 32.5714 38.5 32.5714H9.5C8.67157 32.5714 8 31.8999 8 31.0714V13.5Z" fill="#F2F2F2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 12C8.67157 12 8 12.6716 8 13.5V31.0714C8 31.8999 8.67157 32.5714 9.5 32.5714H20.5714V34.8571L19.4286 36H28.5714L27.4286 34.8571L27.4286 32.5714H38.5C39.3284 32.5714 40 31.8999 40 31.0714V13.5C40 12.6716 39.3284 12 38.5 12H9.5ZM38.5 13.3H9.5C9.38954 13.3 9.3 13.3895 9.3 13.5V31.0714C9.3 31.1819 9.38954 31.2714 9.5 31.2714H38.5C38.6105 31.2714 38.7 31.1819 38.7 31.0714V13.5C38.7 13.3895 38.6105 13.3 38.5 13.3Z" fill="#86909e"/>
        </svg>
    </div>