import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { EolModel } from "../models/eol.model";
import { FileData } from "../models/interfaces/fileData";
@Injectable()
export class EolService {
  private assetUrl = `${environment.assetUrl}/asset`;
  constructor(private http: HttpClient) {}

  getCompanyEolConfig(company_id: number): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/eol`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  updateCompanyEolConfig(company_id: number, data: EolModel): Observable<any> {
    return this.http
      .put(`${this.assetUrl}/company/${company_id}/eol`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchEolConfig(company_id: number, branch_id: number): Observable<any> {
    let query;
    query = branch_id ? `?branch_id=${branch_id}` : "";
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch/config/eol${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  updateBranchEolConfig(
    company_id: number,
    branch_id: number,
    data: EolModel
  ): Observable<any> {
    let query;
    query = branch_id ? `?branch_id=${branch_id}` : "";
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/branch/config/eol${query}`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }
  uploadEolBranchConfigAttachments(
    company_id: number,
    branch_id: number,
    sameSetup: boolean,
    fileDataArray: FileData[]
  ) {
    const branchEolConfigFileInfoPayload = {
      eolBranchConfigId: branch_id,
      sameSetup: sameSetup,
      fileDataArray: fileDataArray,
      companyId: company_id,
    };
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/branch/config/eol/upload`,
        branchEolConfigFileInfoPayload
      )
      .pipe(catchError((error: any) => throwError(error)));
  }
  getEolBanchConfigAttachments(
    company_id: number,
    branch_id: number
  ): Observable<FileData[]> {
    return this.http
      .get(
        `${this.assetUrl}/company/${company_id}/branch/config/eol/attachments?eolBranchConfigId=${branch_id}`
      )
      .pipe(catchError((error: any) => throwError(error))) as Observable<
      FileData[]
    >;
  }
}
