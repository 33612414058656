<div class="m-1">
  <mat-dialog-content class="flex-column">
   <div class="d-flex justify-content-between mb-2 align-items-center">
        <h2 class="center-title mb-0" style="color: #86909E;">{{infoData?.heading}}</h2>
        <span class="material-icons close-modal d-flex" mat-dialog-close>
                <img src="../../../../assets/icons/cancel_black_new.svg" />
        </span>
    </div>
    <div class="divider mb-3"></div>
    <div class="flex-column">
        <h2 class="default-h">{{'MODALS.You' | translate}} [{{infoData?.service_ticket_id}}] {{'MODALS.for' | translate}}:</h2>
        <p class="default-list">{{'MODALS.Device' | translate}} 
          <span class="default-list-item">
            {{infoData?.device?.device_variant ? 
            infoData?.device?.device_variant.description : 
            infoData?.device?.denormalized_device_variant?.name}}
          </span>
        </p>
         <p class="default-list"> {{'MODALS.IMEI' | translate}}: 
           <span class="default-list-item">{{infoData?.device?.imei || 'N/A'}}</span>
          </p>
         <p class="default-list">{{'MODALS.Serial' | translate}}: 
          <span class="default-list-item">
            {{infoData?.device?.serial_number || 'N/A'}}
          </span> 
         </p>
    </div>
    <div class="divider"></div>
    <h2 class="center default-h">{{infoData?.text}}</h2>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
      <button mat-button mat-dialog-close class="cancel-btn">{{'MODALS.Back' | translate | uppercase}}</button>
      <button mat-button (click)="confirm()" class="btn btn-primary">{{'MODALS.Cancel' | translate | uppercase}}</button>
  </mat-dialog-actions>
</div>