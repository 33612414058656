import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BigPriceComponent } from './big-price/big-price.component';
import { MainPipe } from '../pipes/main-pipe.module';
import { ActionDetailsComponent } from './action-details/action-details.component';
import { AdditionalCommentComponent } from "./additional-comment/additional-comment.component";
import { EditDeviceDetailsComponent } from './edit-device-details/edit-device-details.component';
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditUserDetailsComponent } from './edit-user-details/edit-user-details.component';
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { SharedModule } from "..";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ErrorPageComponent } from './error-page/error-page.component';
import { EditBranchDetailsComponent } from "./edit-branch-details/edit-branch-details.component";
import { EditEmployeeDetailsComponent } from './edit-employee-details/edit-employee-details.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ServiceInfoComponent } from './service-info/service-info.component';
import { ActionNeededComponent } from './action-needed/action-needed.component';
import { LoaderComponent } from './loader/loader.component';
import { DeviceTypeIconComponent } from './device-type-icon/device-type-icon.component';
import { DeviceActivityComponent } from './device-activity/device-activity.component';
import { ServiceProgressComponent } from './service-progress/service-progress.component';
import { LifecycleTimelineComponent } from './lifecycle-timeline/lifecycle-timeline.component';
@NgModule({
  declarations: [
    BigPriceComponent, 
    ActionDetailsComponent, 
    AdditionalCommentComponent, 
    EditDeviceDetailsComponent, 
    EditUserDetailsComponent,
    ErrorPageComponent,
    EditBranchDetailsComponent,
    EditEmployeeDetailsComponent,
    NotFoundComponent,
    ProgressBarComponent,
    ServiceInfoComponent,
    ActionNeededComponent,
    LoaderComponent,
    DeviceTypeIconComponent,
    DeviceActivityComponent,
    ServiceProgressComponent,
    LifecycleTimelineComponent,
  ],
  imports: [
    CommonModule, 
    MainPipe, 
    MatInputModule, 
    MatSelectModule,
    MatNativeDateModule, 
    MatDatepickerModule, 
    MatCheckboxModule, 
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    SharedModule,
    MatAutocompleteModule,
  ],
  exports: [
    BigPriceComponent, 
    ActionDetailsComponent, 
    AdditionalCommentComponent, 
    EditDeviceDetailsComponent,
    EditUserDetailsComponent,
    EditBranchDetailsComponent,
    EditEmployeeDetailsComponent,
    ProgressBarComponent,
    ServiceInfoComponent,
    ActionNeededComponent,
    LoaderComponent,
    DeviceTypeIconComponent,
    LoaderComponent, 
    DeviceActivityComponent,
    ServiceProgressComponent,
    LifecycleTimelineComponent
  ]
})
export class ComponentsModule {}
