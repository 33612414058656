import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-type-icon',
  templateUrl: './device-type-icon.component.html',
  styleUrls: ['./device-type-icon.component.scss']
})
export class DeviceTypeIconComponent implements OnInit {
  @Input() deviceTypeId: number;
  @Input() selected?: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
