import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNames'
})
export class FileNamesPipe implements PipeTransform {
  transform(files: File[] | null): string[] {
    if (!files || !files.length) return [];
    
    return files
      .map(file => file.name)
  }
}
