<div class="container-fluid">
  <div class="navbar fixed-top">
    <div class="header-primary">
      <!-- breadcrumbs -->
      <!-- breadcrumbs -->
      <div class="left-header">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active">
            <app-breadcrumb></app-breadcrumb>
          </li>
        </ol>
      </div>
      <!-- branch picker -->
      <div class="right-header">
        <div>
          <form class="form-inline search nav-bar-search" [formGroup]="filterForm" *ngIf="isAtDashboard()">
            <div class="btn-group w-300 d-flex align-items-center" role="group">
              <mat-form-field class="full-width">
                <mat-label>{{'BRANCHLIST.Search branches' | translate }}</mat-label>
                <input type="text" matInput formControlName="name" #panel [matAutocomplete]="auto4"
                  (change)="clearFilter($event.target.value)" />
           
                <mat-autocomplete autoActiveFirstOption (optionSelected)="emitReloadDashboardEvent()"
                  #auto4="matAutocomplete" [displayWith]="branchAutocompleteView">
                  <div *ngIf="currentUser?.user_roles?.includes('manager')" class="device-serial-option">
                    <mat-option>
                      All Branches
                    </mat-option>
                    <mat-option *ngFor="let branch of branches" [value]="branch">
                      {{branch?.name}}
                    </mat-option>
                  </div>
                  <div *ngIf="hasPermission" class="device-serial-option">
                    <mat-option>
                      All Branches
                    </mat-option>
                    <mat-option *ngFor="let branch of branches" [value]="branch">
                      {{branch?.name}}
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-form-field>
              <span class="material-icons branches-search-icon" (click)="openAutocompletePanel($event)">
                search
              </span>
            </div>
          </form>
        </div>
        <!-- roadmap -->
        <div class="roadmap">
          <span class="material-icons" (click)="navigate()">
            route
            </span>
        </div>
        <!-- user -->
        <div class="nav-user" *ngIf="internalUser || externalUser">
          <div class="ml-4"></div>
          <div class="avatar">
            <span> {{ internalUser ? (currentUser | initials) : 'A1' }}</span>
          </div>
          <div class="nav-user-profile dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ internalUser ? currentUser.first_name + ' ' + currentUser.last_name : 'A1 User' }}
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-pointer-header" aria-labelledby="dropdownMenuLink">
              <a
                *ngIf="internalUser || externalUser"
                class="dropdown-item dropdown-item-form"
                [routerLink]="['/users/api']"
              >
                {{ 'NAVBAR.Api key' | translate }}
                <br>
              </a>
        
              <!-- Internal User Specific Links -->
              <ng-container *ngIf="internalUser">
                <a
                  class="dropdown-item dropdown-item-form"
                  [routerLink]="['/users/profile', currentUser.id]"
                >
                  {{ 'NAVBAR.Profile' | translate }}
                  <br>
                </a>
                <a
                  *ngIf="!currentUser?.user_roles?.includes('manager')"
                  class="dropdown-item"
                  [routerLink]="['/users/account']"
                >
                  {{ 'NAVBAR.Company info' | translate }}
                  <br>
                </a>
                <div class="dropdown-divider"></div>
                <a
                  class="sign-out-link"
                  (click)="logout()"
                  style="color: red;"
                >
                  <span class="material-symbols-outlined">
                  logout
                  </span>
                  {{ 'NAVBAR.Sign out' | translate }}
                </a>
              </ng-container>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <!-- header -->
  </div>
</div>
