<div>
    <div [ngClass]="{'backdrop': showInfo}" (click)="closeForm()"></div>
    <div class="panel-wrap" [ngClass]="{'panel-show': showInfo}">
        <div class="panel">
            <div class="nav">
                <h1 class="mb-0"> Edit Manager Details</h1> <span (click)="closeForm()"><img
                        src="../../../../assets/icons/x-button.png"></span>
            </div>
            <hr class="divider"/>
            <form class="form ng-pristine example-form" [formGroup]="userForm" id="ngForm" (ngSubmit)="saveUser()">
                <div class="row">
                    <div class="col-12">
                        <div class="card-multi">
                            <div class="card-block">
                                <div>
                                    <p class="p-title">{{'USERFORM.General information' | translate }}</p>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>{{'USERFORM.First name' | translate }}</mat-label>
                                            <input matInput placeholder="{{'USERFORM.First name' | translate }}"
                                                formControlName="first_name" required>
                                            <mat-error *ngIf="userForm.get('first_name').hasError('required')">
                                                {{'USERFORM.First name is' | translate }} <strong>{{'USERFORM.required'
                                                    | translate }}</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-6">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>{{'USERFORM.Last name' | translate }}</mat-label>
                                            <input matInput placeholder="{{'USERFORM.Last name' | translate }}"
                                                formControlName="last_name" required>
                                            <mat-error *ngIf="userForm.get('last_name').hasError('required')">
                                                {{'USERFORM.Last name is' | translate }} <strong>{{'USERFORM.required' |
                                                    translate }}</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-md-12 col-lg-3">
                                        <p class="p-title">{{'USERFORM.Choose permission' | translate }}</p>
                                    </div>
                                </div>
                                <div class="row" formGroupName="permissions">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group read_only_checkbox">
                                            <mat-checkbox formControlName="read_only">{{'USERFORM.Read only' | translate
                                                }}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-md-12 col-lg-3">
                                        <p class="p-title">{{'USERFORM.Contact and ID' | translate }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'USERFORM.Email address' | translate }}</mat-label>
                                                <input matInput type="email"
                                                    placeholder="{{'USERFORM.Email address' | translate }}"
                                                    formControlName="email" required>
                                                <mat-error
                                                    *ngIf="userForm.get('email').hasError('email') && !userForm.get('email').hasError('required')">
                                                    {{'USERFORM.Please enter a valid email address' | translate }}
                                                </mat-error>
                                                <mat-error *ngIf="userForm.get('email').hasError('required')">
                                                    {{'USERFORM.Email is' | translate }} <strong>{{'USERFORM.required' |
                                                        translate }}</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class=" form-group">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'USERFORM.Phone' | translate }}</mat-label>
                                                <input matInput placeholder="{{'USERFORM.Phone' | translate }}"
                                                    formControlName="phone_number">
                                                    <mat-error>
                                                        {{'USERFORM.Phone number is not' | translate }} <strong> {{'USERFORM.valid' | translate }}</strong>
                                                      </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-md-12 col-lg-3">
                                        <p class="p-title">{{'USERFORM.Corporation info' | translate }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group" *ngIf="manager">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <mat-form-field class="full-width" appearance="outline">
                                                        <mat-label>
                                                            <mat-icon
                                                                class="material-icons-outlined sidebar-icons larger-icon-18">
                                                                business </mat-icon>
                                                            {{'USERFORM.Branch' | translate }}</mat-label>
                                                        <mat-select multiple formControlName="branch_id">
                                                            <mat-option *ngFor="let branch of branches"
                                                                [value]="branch">
                                                                {{branch.name}}
                                                            </mat-option>
                                                            <mat-error
                                                                *ngIf="userForm.get('branch_id').hasError('required')">
                                                                {{'USERFORM.Branch is' | translate }}
                                                                <strong>{{'USERFORM.required' | translate }}</strong>
                                                            </mat-error>
                                                        </mat-select>

                                                    </mat-form-field>
                                                </div>
                                                <div class="col-lg-12 d-flex flex-wrap"
                                                    *ngIf="userForm.get('branch_id').value">
                                                    <div class="checked app-badge-pill"
                                                        *ngFor="let branches of userForm.get('branch_id').value; let i = index">
                                                        {{branches.name}}<a (click)="deleteItem($event, i)"
                                                            class="delete-x-button"><img
                                                                src=" ../../../../../../assets/icons/x-button-blue.png" /></a>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 align-items-center d-flex">
                                                    <section class="example-section">
                                                        <mat-checkbox class="checkbox"
                                                            [checked]="user.assigned_all_branches"
                                                            (change)="allBranchesSelected($event.checked)">{{'USERFORM.All
                                                            branches' | translate }}</mat-checkbox>
                                                    </section>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class=" form-group">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'USERFORM.Corporation ID' | translate }}</mat-label>
                                                <input matInput formControlName="corporate_id">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class=" form-group">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'USERFORM.Cost Center' | translate }}</mat-label>
                                                <input matInput formControlName="cost_center">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class=" form-group">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'USERFORM.Department' | translate }}</mat-label>
                                                <input matInput formControlName="department">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                   
                                </div>
                            </div>
                            <div class="card-block">
                                <div>
                                    <p class="p-title">{{'USERFORM.Additional info' | translate }}</p>
                                </div>
                                <div>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>{{'USERFORM.Additional Note' | translate}}</mat-label>
                                        <textarea matInput rows="3" formControlName="additional_note"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="col-12 px-0 d-flex justify-content-between">
                                    <p>* Required fields</p>
                                </div>
                            </div>
                        </div>
                        <div class="buttons">
                            <a class="btn btn-cancel mr-3" role="button" [routerLink]="['/users/list']"
                                (click)="closeForm()">{{'USERFORM.Cancel' | translate | titlecase }}</a>
                            <button type="submit" [disabled]="!submitable"
                                class="btn btn-primary">{{'USERFORM.Save changes' | translate | uppercase |
                                titlecase }}</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>