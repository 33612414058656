import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActionNeeded, DeviceModel, ServiceStepCompletedModel, UserModel } from '../../models';
import { Enums } from '../../enums/lists.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeviceService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActionNeededResolverComponent } from '../../modal-dialogs/action-needed-resolver/action-needed-resolver.component';
import { ResolverConfirmationComponent } from '../../modal-dialogs/resolver-confirmation/resolver-confirmation.component';
import { CancelDeviceScComponent } from '../../modal-dialogs/cancel-device-sc/cancel-device-sc.component';

@Component({
  selector: 'app-service-progress',
  templateUrl: './service-progress.component.html',
  styleUrls: ['./service-progress.component.scss']
})
export class ServiceProgressComponent implements OnInit {
  @Input() device: DeviceModel = new DeviceModel();
  @Input() currentUser: UserModel = new UserModel();
  @Output() getDevice: EventEmitter<boolean> = new EventEmitter();
  @Output() changeStatus: EventEmitter<boolean> = new EventEmitter();

  isSCCanceled: boolean;
  icons: any = [];
  actionNeededFiltered: ServiceStepCompletedModel[];
  service_index_map = {
    0: 1,
    1: 4,
    2: 6,
    3: 7,
    4: 13,
    5: 15,
    6: 20
  }
  public actionNeededText = null;
  lastServiceStepCompleted: any;
  lastStepCompletedDate: string | null = null;
  deviceHasUnresolvedActionNeededStep: boolean;
  public ticketID: string;
  public stepMsg: string;
  public internalUser;
  cancelEnabled: boolean = true;
  public currentStatusText = null;
  public dialogConfig = new MatDialogConfig();
  public generatedFile: { file: string };
  public resolveConfirmation = new MatDialogConfig();
  public deviceSCModal = new MatDialogConfig();
  public actionConfig = new MatDialogConfig();

  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private toastr: ToastrService,
    private enums: Enums,
    public translate: TranslateService,
  ) {
    this.icons = this.enums.stepsGrayIcons;
  }

  ngOnInit(): void {
    this.handleServiceCaseStatus()
  }

  public isServiceStepCompleted(index?: number) {
    const real_index = this.service_index_map[index];

    if (index === 3) {

      const completed_service_steps: ServiceStepCompletedModel[] = this.getCompletedServiceSteps();

      this.actionNeededFiltered = completed_service_steps.filter(step => [8, 10, 11, 12].includes(step.service_step_id));

      const unresolved = this.device?.action_neededs.filter(action => action.service_case_id == this.actionNeededFiltered[0]?.service_case_id && !action.resolved)[0];
      if (this.actionNeededFiltered.length > 0 && unresolved) {
        this.actionNeededText = this.actionNeededFiltered[this.actionNeededFiltered.length - 1]?.service_step?.message;
      }

      return this.actionNeededFiltered.length > 0;
    }
    return real_index <= this.lastServiceStepCompleted;
  }

  private getCompletedServiceSteps(): ServiceStepCompletedModel[] {
    return this.device?.service_cases[this.device?.service_cases?.length - 1]?.service_step_completeds ?? [];
  }


  handleServiceCaseStatus(): void {
    const completedSteps: ServiceStepCompletedModel[] = this.getCompletedServiceSteps();

    this.updateServiceStatusDetails(completedSteps);
    this.checkCancellationAvailability(completedSteps);
    this.deviceHasUnresolvedActionNeededStep = this.hasBlockerStep(completedSteps) && this.lastServiceStepCompleted <= 12;
  }

  private updateServiceStatusDetails(completedSteps: ServiceStepCompletedModel[]): void {
    const lastStep = completedSteps[completedSteps.length - 1];
    this.isSCCanceled = this.device?.service_cases[this.device?.service_cases?.length - 1]?.cancelled;
    this.stepMsg = lastStep?.service_step?.message;
    this.ticketID = this.device?.service_cases[this.device?.service_cases?.length - 1]?.ticket_id;
    this.lastServiceStepCompleted = lastStep?.service_step_id;
    this.lastStepCompletedDate = completedSteps.find(step => step.service_step_id === this.lastServiceStepCompleted)?.completed_at;
    this.currentStatusText = lastStep?.service_step.message;
  }

  //Is user enabled to cancel service case
  private checkCancellationAvailability(completedSteps: ServiceStepCompletedModel[]): void {
    this.cancelEnabled = this.deviceService.isCancelEnabled(completedSteps);
  }

  // check does next step and blocker exist
  private hasBlockerStep(completedSteps: ServiceStepCompletedModel[]): boolean {
    return completedSteps.some(step => [8, 10, 11, 12].includes(step.service_step_id));
  }

  async resolveServiceActionNeeded(device: DeviceModel): Promise<void> {
    this.configureDialog();

    if (this.actionNeededFiltered[0]?.service_step_id === 12) {
      this.generatedFile = await this.getServiceStepPDF(device);
    }

    const action = this.getUnresolvedAction(device);
    this.openActionNeededModal(device, action);
  }

  private configureDialog(): void {
    this.dialogConfig = {
      width: '550px',
      height: '100vh',
      position: { right: '0px' },
      panelClass: ['animate__animated', 'animate__slideInRight', 'animate__faster']
    };
  }

  private getUnresolvedAction(device: DeviceModel): ActionNeeded {
    const action = device?.action_neededs.find(a => a.type.startsWith('service-case') && !a.resolved);
    if (action) {
      action.service_step_id = this.actionNeededFiltered[0]?.service_step_id;
      action.service_step = this.actionNeededFiltered[0]?.service_step;
      action.additional_info = this.actionNeededFiltered[0]?.additional_info;
    }
    return action;
  }

  private openActionNeededModal(device: DeviceModel, action: ActionNeeded): void {
    this.dialogConfig.data = { device, action, file: this.generatedFile?.file || '' };
    const actionNeededModal = this.dialog.open(ActionNeededResolverComponent, this.dialogConfig);

    actionNeededModal.afterClosed().subscribe(res => {

      if (res) {
        this.openResolveConfirmationModal(device, res)
      }
    });
  }

  private openResolveConfirmationModal(device: DeviceModel, res: any): void {

    this.resolveConfirmation = { width: '792px', height: 'auto' };
    const dataMap = {
      8: { text: 'DEP Active', response: res?.response === 'No' ? 'No, I cannot deactivate DEP.' : 'Yes, I have deactivate DEP.' },
      10: { text: 'FMI Active', response: res?.response === 'No' ? 'No, I cannot deactivate FMI.' : 'Yes, I have deactivate FMI.' },
      11: { text: 'Additional Info', response: 'Thank you for your feedback.' },
      12: { text: 'Additional costs confirmation', response: res?.response === 'No' ? 'No, I do not accept the repair offer.' : 'Yes, I accept the repair offer.' }
    };

    this.resolveConfirmation.data = { ...res, ...dataMap[res?.action?.service_step_id], file: this.generatedFile?.file || null };

    const resolveConfirmation = this.dialog.open(ResolverConfirmationComponent, this.resolveConfirmation);
    resolveConfirmation.afterClosed().subscribe(resolverData => {
      if (resolverData) {
        this.resolveServiceAction(device, resolverData)
      };
    });
  }

  private resolveServiceAction(device: DeviceModel, resolverData: any): void {
    this.deviceService.resolveAction(device.company_id, device.id, resolverData)
      .subscribe(() => this.toastr.success('', this.translate.instant('NOTIFICATIONS.Action resolve info')));
  }

  public getServiceStepPDF(device) {
    return this.deviceService.getServiceStepPDFOffer(device?.company_id, device?.id, this.actionNeededFiltered[0].service_case_id, "sales_quote")
      .toPromise()
      .catch(err => this.toastr.error('', err.error.message));
  }

  nextStep(val) {
    const key = Object.keys(this.service_index_map).find(key => this.service_index_map[key] === val);
    return +key;
  }

  public cancelServiceCase(serviceTicketId: string) {
    this.deviceSCModal.width = '792px';
    this.deviceSCModal.height = 'auto';
    this.deviceSCModal.data = {
      heading: this.translate.instant('CANCELSERVICECASEPOPUP.Cancel Service Case'),
      text: this.translate.instant('CANCELSERVICECASEPOPUP.Are you sure'),
      device: this.device,
      serviceTicketId
    }
    const serviceCaseID = this.device?.service_cases[this.device?.service_cases.length - 1];
    const canceletionModal = this.dialog.open(CancelDeviceScComponent, this.deviceSCModal);
    canceletionModal.afterClosed().subscribe(res => {
      if (res) {
        this.deviceService.cancelServiceStep(this.device?.company_id, this.device?.id, serviceCaseID.id)
          .subscribe(res => {
            this.toastr.success('', this.translate.instant('NOTIFICATIONS.Service canceled'));
            this.getDevice.emit(true);
          }, error => {
            this.toastr.error('', error.error.message);
          });
      }
    });
  }

  public changeStatusEmmiter(): void {
    this.changeStatus.emit(true)
  }
}
